// Once feature flags are removed and features are added permanently, the key
// should be moved from `FlaggedNewUserFeatureSet` to
// `EnabledNewUserFeatureSet`.

export interface FlaggedNewUserFeatureSet {
  "show-templates-tooltip": boolean;
}

interface EnabledNewUserFeatureSet {}

export type FlaggedNewUserFeature = keyof FlaggedNewUserFeatureSet;

export type NewUserFeatureSet = FlaggedNewUserFeatureSet &
  EnabledNewUserFeatureSet;

export type PartialNewUserFeatureSet = {
  [K in keyof NewUserFeatureSet]?: NewUserFeatureSet[K];
};

// Default feature flag fallback value (if LD not available). We also iterate
// over the keys in this const to determine which new user features to evaluate
// flags for.
export const NewUserFeatureFlagDefaults: FlaggedNewUserFeatureSet = {
  "show-templates-tooltip": false, // this flag is no longer used, but leaving it here as an example
};

// Default new user feature value (should always be true).We also iterate
// over the keys in this const to determine which new user features to evaluate
// flags for.
export const EnabledNewUserFeatures: EnabledNewUserFeatureSet = {};

export const isNewUserFeature = (
  flag: string,
): flag is keyof NewUserFeatureSet => {
  return flag in NewUserFeatureFlagDefaults || flag in EnabledNewUserFeatures;
};
